import { Controller } from "@hotwired/stimulus"
import { patch } from '@rails/request.js'

// Connects to data-controller="action-confirmation"
export default class extends Controller {
  connect() {
  }

  handleChange(event) {
    const selectedValue = event.target.value;
    const actionId = this.element.dataset.actionId;

    patch(`/dashboard/actions/${actionId}/update_confirmation_state`, {
      contentType: "application/json",
      responseKind: "json",
      body: JSON.stringify({ confirmation_state: selectedValue }),
    }).then(() => { location.reload(); });
  }
}
